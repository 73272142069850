import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  createRecordWithHook,
  getByCode,
  updateRecordWithHook,
} from "@ui/ComponentUtils/blueprintAPIs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
});

const entitySchema = {
  name: "",
  products: [],
};

const CatalogueDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models.catalogues;
  const [loading, setLoading] = useState(false);

  const [availableProducts, setAvailableProducts] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      if (!(values.includedUsers?.length > 0)) {
        return CustomEventEmitter.emit(
          "alert_error",
          "Include at least 1 user to continue..."
        );
      }

      let res = {},
        action = "Created";

      if (!values._id) {
        res = await createRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
        action = "Updated";
      }
      formMethods.reset(res);
      setIsEditable(false);

      CustomEventEmitter.emit(
        "alert_success",
        action + " Successfully"
        // `Existing: ${res.existing}\nRemoved: ${res.removed}\nUpdated: ${res.updated}`
      );
    } catch (err) {
      console.log(err);
      let message = err?.response?.data?.message || "Something went wrong";
      if (err?.response?.data?.message?.toString().match("duplicate")) {
        message =
          values.name + " already exists. Change the name and try again.";
      }
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default CatalogueDetail;

export const catalogueStyles = {
  section: {
    // backgroundColor: "#ffeba1",
    padding: "24px",
  },
  heading: {
    backgroundColor: "ff6f00",
  },
};
