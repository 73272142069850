import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAsyncData } from "./api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";

const MyAsyncAutocomplete = ({
  rules,
  label,
  name,
  apiUrl,
  apiMethod = "post",
  apiBody,
  required = false,
  defaultValue,
  sortBy,
  groupBy,
  includeOption,
  dynamicAPIbody = false,
  hideAction = { successMessage: false },
  InputLabelProps,
  InputProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  apiBody =
    apiBody && Object.keys(apiBody).find((key) => apiBody[key]) && apiBody;
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let response = [];
      const noneObject = {
        _id: enums.keywords.empty,
        name: enums.keywords.empty,
      };
      try {
        response = (await getAsyncData(apiUrl, apiMethod, apiBody || {})) || [];

        if (response?.length) {
          if (!hideAction.successMessage) {
            CustomEventEmitter.emit(
              "alert_success",
              `${response.length} option${
                response.length > 1 ? "s" : ""
              } loaded`
            );
          }

          if (sortBy) {
            response.sort((a, b) => {
              if (a[sortBy] < b[sortBy]) {
                return -1;
              }
              if (a[sortBy] > b[sortBy]) {
                return 1;
              }
              return 0;
            });
          }

          if (active) {
            if (includeOption?.none) {
              response = [noneObject, ...response];
            }
          }
        } else {
          if (includeOption?.none) {
            response = [noneObject];
          }

          CustomEventEmitter.emit("alert_success", `Nothing found`);
        }
      } catch (err) {
        console.log(err);
        CustomEventEmitter.emit("alert_error", JSON.stringify(err));
      }
      if (active) setOptions(response);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (apiBody && dynamicAPIbody) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      groupBy={groupBy}
      options={options || []}
      loading={loading}
      size="small"
      renderInput={(textProps) => (
        <TextField
          {...textProps}
          required={required}
          error={textProps.error}
          helperText={textProps.helperText}
          size="small"
          variant="outlined"
          label={label}
          InputProps={{
            ...textProps.InputProps,
            ...InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {InputProps?.endAdornment ? InputProps?.endAdornment : null}
              </>
            ),
          }}
          InputLabelProps={InputLabelProps}
        />
      )}
    />
  );
};

export const freeSoloReasonConstants = {
  selected: "select-option",
  created: "create-option",
  removed: "remove-option",
};
export default MyAsyncAutocomplete;
