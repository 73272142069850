import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { catalogueStyles } from "../Detail";
import { syncCatToProducts } from "views/Catalogues/api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import commonConfig from "config/commonConfig";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;
  const [users, setUsers] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.catalogues;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let res = await search(enums.models.users);

        res.sort((a, b) => a.role?.localeCompare(b.role));

        setUsers(
          [
            { firstName: enums.keywords.includedUsers.public, role: "Auto" },
            { firstName: enums.keywords.includedUsers.private, role: "Auto" },
            { firstName: enums.keywords.selectAll, role: "Auto" },
            ...res,
          ] || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      // xs: 12,
      name: "includedUsers",
      label: "Include Users",
      fieldType: "dropdown",
      multiple: true,
      render: "firstName",
      renderFunc: (option) =>
        (option?.firstName || "") +
        " " +
        (option?.lastName || "") +
        (option?.phone ? ` (${option?.phone})` : ""),
      extraOptions: 3,
      options: users || [],
      onChange: (_, value) => {
        const isSelectAll = value?.find(
          (value) => value.firstName === enums.keywords.selectAll
        );

        if (isSelectAll) {
          let allUsers = users ? [...users] : [];

          if (values.includedUsers?.length === users.length - 3) {
            allUsers = [];
          } else {
            allUsers.shift();
            allUsers.shift();
            allUsers.shift();
          }

          setValue("includedUsers", allUsers);
        }
      },
      getOptionLabel: (opt) =>
        (opt?.firstName || "") +
        " " +
        (opt?.lastName || "") +
        (opt?.phone ? ` (${opt?.phone})` : ""),
      groupBy: (option) => option?.role,
      getOptionSelected: (option, value) =>
        (option?.firstName || "") +
          " " +
          (option?.lastName || "") +
          (option?.phone ? ` (${option?.phone})` : "") ===
        (value?.firstName || "") +
          " " +
          (value?.lastName || "") +
          (value?.phone ? ` (${value?.phone})` : ""),
      renderOption: enums.keywords.selectAll,
    },
    // {
    //   label: "Public sharing URL",
    //   copyToClipboard: true,
    //   xs: 12,
    //   hidden: !values._id || isEditable,
    //   value: `${commonConfig.hyperLinks["e-creatives"]}#/public/${
    //     enums.models.catalogues
    //   }/${values.client?._id || values.client}/${values._id}`,
    // },
    // {
    //   xs: 8,
    //   name: "products",
    //   label: "Products",
    //   fieldType: "dropdown",
    //   multiple: true,
    //   render: "styleNo",
    //   extraOptions: 1,
    //   options: products || [],
    //   onChange: (_, value) => {
    //     const isSelectAll = value?.find(
    //       (value) => value.styleNo === enums.keywords.selectAll
    //     );

    //     const isCat = uniqueCats.find((cat) =>
    //       value?.find((prod) => prod._id === cat._id)
    //     );

    //     if (isSelectAll) {
    //       let allProducts = products ? [...products] : [];
    //       allProducts = allProducts.filter((prod) =>
    //         uniqueCats?.find((cat) => cat?.name === prod?.category?.name)
    //       );

    //       if (
    //         values.products?.length ===
    //         products.length - uniqueCats?.length - 1
    //       ) {
    //         allProducts = [];
    //       }

    //       setValue("products", allProducts);
    //     } else if (isCat) {
    //       let allProducts = [...values.products] || [];
    //       let allCatProducts = products
    //         ? [
    //             ...products?.filter(
    //               (prod) => prod?.category?._id === isCat?._id
    //             ),
    //           ]
    //         : [];

    //       const existingCatProds =
    //         values.products?.filter(
    //           (prod) => prod?.category?._id === isCat?._id
    //         ) || [];

    //       if (existingCatProds?.length === allCatProducts.length) {
    //         allProducts = allProducts.filter(
    //           (prd) => prd?.category?._id !== isCat?._id
    //         );
    //       } else {
    //         allProducts = uniqueArray([...allProducts, ...allCatProducts]);
    //       }

    //       setValue("products", allProducts);
    //     }
    //   },
    //   getOptionLabel: (opt) => opt?.styleNo || "",
    //   // groupBy: (option) => option?.category?.name,
    //   getOptionSelected: (option, value) => option?.styleNo === value?.styleNo,
    //   renderOption: (option, state) => {
    //     const values = formMethods.getValues();
    //     const isCat = uniqueCats.find((cat) => cat._id === option?._id);

    //     if (option.styleNo === enums.keywords?.selectAll || isCat) {
    //       const sameCats = values.products?.filter(
    //         (prod) =>
    //           products?.find((cp) => cp?._id === prod?._id)?.category?._id ===
    //           isCat?._id
    //       );

    //       if (isCat) {
    //         if (sameCats.length === isCat?.count) {
    //           state.selected = true;
    //         } else {
    //           state.selected = false;
    //         }
    //       } else if (
    //         values.products?.length ===
    //         products?.length - uniqueCats?.length - 1
    //       ) {
    //         state.selected = true;
    //       } else {
    //         state.selected = false;
    //       }

    //       return (
    //         <React.Fragment>
    //           <Checkbox
    //             icon={icon}
    //             checkedIcon={checkedIcon}
    //             // style={{}}
    //             checked={state.selected}
    //           />
    //           {option?.styleNo}
    //         </React.Fragment>
    //       );
    //     } else {
    //       return <React.Fragment>{option?.styleNo}</React.Fragment>;
    //     }
    //   },
    // },
  ];

  const handleSync = async () => {
    try {
      const { existing, updated, removed } = await syncCatToProducts(values);
      setValue("frontend.sync", new Date()); // updated value is not used, just to give values.frontend.sync in useEffect dependancy, this is done.
      CustomEventEmitter.emit(
        "alert_success",
        `Products\nExisting: ${existing}\nRemoved: ${removed}\nUpdated: ${updated}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const links = [
    {
      name: "Catalogues",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      label: "Sync Products",
      iconName: "sync",
      hidden: isEditable,
      onClick: handleSync,
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Catalogues"} actions={actions} />
      <GeneralSection
        style={catalogueStyles.section}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      ></GeneralSection>
    </>
  );
};

export default DetailHeader;
