import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import DomainIcon from "../assets/domain.png";
// import HTTPIcon from "../assets/http.png";

import Emitter from "@ui/Utils/CustomEventEmitter";
import { createExpiryLink } from "../api";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import enums from "helpers/enums";
import { formatSecondsToRead } from "@ui/Utils/helper";

import MyAsyncAutocomplete from "@ui/MuiComponents/AutoComplete/MyAsyncAutocomplete";

const localStorageKey = "LINKit/expiryTimeout"; // Define localStorage key

const LINKit = ({ columns }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [validUsers, setValidUsers] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [expiryTimeout, setExpiryTimeout] = useState({
    months: "",
    weeks: "",
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [totalSeconds, setTotalSeconds] = useState(0);

  // Load initial state from localStorage on component mount
  useEffect(() => {
    const storedTimeout = localStorage.getItem(localStorageKey);
    if (storedTimeout) {
      setExpiryTimeout(JSON.parse(storedTimeout));
    }
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleLINKit = async () => {
    try {
      if (
        !validUsers.length ||
        !catalogues.length ||
        !Object.values(expiryTimeout).find(
          (timeUnit) => timeUnit?.toString().trim() && Number(timeUnit) != 0
        )
      ) {
        return Emitter.emit("alert_error", "Fill required fields to continue");
      }

      setOpenDialog(false);

      const res = await createExpiryLink({
        expiryTimeout,
        validUsers,
        catalogues,
      });

      // Automatically copy URL to clipboard (example)
      // Replace with actual implementation based on your setup
      navigator.clipboard.writeText(res?.url);
      Emitter.emit(
        "alert_success",
        `Copied to Clipboard 🎮 ${res?.expiresIn} 🎲`
      );

      setValidUsers([]);
      setCatalogues([]);

      // Save to localStorage after successful link
      localStorage.setItem(localStorageKey, JSON.stringify(expiryTimeout));

      handleCloseDialog();
    } catch (error) {
      console.error("Error linking URL:", error.response);
      Emitter.emit(
        "alert_error",
        error?.response?.data?.message
          ? error.response.data.message + " ⚠️"
          : "Try Again ⚠️"
      );
    }
  };

  const calculateSeconds = (expiryTimeout) => {
    const { months, weeks, days, hours, minutes, seconds } = expiryTimeout;

    const totalSeconds =
      parseFloat(months || 0) * 30 * 24 * 60 * 60 +
      parseFloat(weeks || 0) * 7 * 24 * 60 * 60 +
      parseFloat(days || 0) * 24 * 60 * 60 +
      parseFloat(hours || 0) * 60 * 60 +
      parseFloat(minutes || 0) * 60 +
      parseFloat(seconds || 0);

    return totalSeconds;
  };

  // Effect to update total seconds as user types
  useEffect(() => {
    const seconds = calculateSeconds(expiryTimeout);
    setTotalSeconds(seconds);
  }, [expiryTimeout]);

  // Function to determine background color based on total seconds
  const getBackgroundColor = () => {
    if (totalSeconds <= 60 * 60) {
      return "#FFCCCC";
    } else if (totalSeconds <= 60 * 60 * 12) {
      return "#FFF3CD";
    } else {
      return "#C3E6CB";
    }
  };

  const handleInputChange = (field) => (e) => {
    if (Number(e.target.value) < 0) {
      return Emitter.emit("alert_error", "Invalid time");
    }
    const newExpiryTimeout = {
      ...expiryTimeout,
      [field]: e.target.value,
    };
    const newTotalSeconds = calculateSeconds(newExpiryTimeout);

    if (newTotalSeconds <= 6 * 30 * 24 * 60 * 60) {
      // Check if total seconds are less than or equal to 1 month
      setExpiryTimeout(newExpiryTimeout);
    } else {
      Emitter.emit("alert_error", "Total expiry time cannot exceed 6 months.");
    }
  };

  return (
    <>
      <GeneralListPage
        columns={columns}
        model={enums.models.catalogues}
        headerTitle="Catalogues"
        searchFields={["name"]}
        hideAction={{ delete: true, selection: true }}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "550px",
              cursor: "pointer",
            }}
            onClick={handleOpenDialog}
          >
            <span
              style={{ marginRight: "8px", color: enums.colorTheme.primary }}
            >
              SHARE it
            </span>
            <img
              src={DomainIcon}
              style={{
                height: "32px",
                width: "32px",
                filter:
                  "invert(35%) sepia(73%) saturate(2362%) hue-rotate(160deg) brightness(96%) contrast(104%)",
              }}
            />
          </div>
        }
      />

      {/* Dialog for input */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ backgroundColor: getBackgroundColor() }}>
          EXPIRE it
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "24px" }}>
            <Grid item xs={6}>
              <MyAsyncAutocomplete
                apiUrl={enums.models.users + "/search"}
                label="Customers *"
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(opt) =>
                  opt.firstName
                    ? `🧑🏻‍🏫 ${opt.firstName} ${opt.lastName || ""}${
                        opt.companyDetails?.name
                          ? `, 🏰 ${opt.companyDetails?.name}`
                          : ""
                      }; ${opt.phone} ☏`
                    : ""
                }
                value={validUsers}
                multiple={true}
                onChange={(_, v) =>
                  setValidUsers(
                    (v || []).map((user) => ({
                      _id: user._id,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      phone: user.phone,
                      role: user.role,
                      companyDetails: user.companyDetails,
                    }))
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <MyAsyncAutocomplete
                label="Catalogues *"
                apiUrl={enums.models.catalogues + "/search"}
                getOptionLabel={(opt) => (opt.name ? opt.name + " ⚡️" : "")}
                getOptionSelected={(opt, val) =>
                  opt?.name + " ⚡️" == val?.name + " ⚡️"
                }
                multiple={true}
                value={catalogues}
                onChange={(_, v) =>
                  setCatalogues(
                    (v || []).map((cat) => ({ name: cat.name, _id: cat._id }))
                  )
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="months"
                label="Months"
                type="number"
                value={expiryTimeout.months}
                onChange={handleInputChange("months")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="weeks"
                label="Weeks"
                type="number"
                value={expiryTimeout.weeks}
                onChange={handleInputChange("weeks")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="days"
                label="Days"
                type="number"
                value={expiryTimeout.days}
                onChange={handleInputChange("days")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="hours"
                label="Hours"
                type="number"
                value={expiryTimeout.hours}
                onChange={handleInputChange("hours")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="minutes"
                label="Minutes"
                type="number"
                value={expiryTimeout.minutes}
                onChange={handleInputChange("minutes")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="seconds"
                label="Seconds"
                type="number"
                value={expiryTimeout.seconds}
                onChange={handleInputChange("seconds")}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Display live seconds conversion */}
          <div
            style={{
              marginTop: "10px",
              backgroundColor: getBackgroundColor(),
              padding: "10px",
              borderRadius: "4px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: totalSeconds > 7 * 24 * 3600 ? "14px" : "inherit", // Adjust font size for longer durations
              width: "fit-content", // Set width to fit content
              minWidth: "150px", // Minimum width to prevent too narrow width
            }}
          >
            <pre>{formatSecondsToRead(totalSeconds)} *</pre>
          </div>
        </DialogContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ flex: 0.4, color: "black" }}
            onClick={handleCloseDialog}
            color="primary"
            onMouseEnter={(e) => (e.target.style.fontWeight = "bold")}
            onMouseLeave={(e) => (e.target.style.fontWeight = "")}
          >
            X
          </Button>
          <Button
            onMouseEnter={(event) => {
              event.target.style.backgroundColor = "#075985"; // Swap background color on hover
              event.target.style.color = "#EFF6FF";
            }}
            onMouseLeave={(event) => {
              event.target.style.backgroundColor = "#EFF6FF"; // Original background color
              event.target.style.color = "#075985";
            }}
            style={{
              background: "#EFF6FF",
              color: "#075985",
              flex: 1,
            }}
            onClick={handleLINKit}
            color="primary"
          >
            {/* <Tooltip title="LINK it"> */}

            {/* <img
                src={HTTPIcon}
                alt="HTTP Icon"
                style={{ height: "60px", width: "60px" }}
              /> */}
            {/* </Tooltip> */}
            <pre style={{ textTransform: "none" }}>LINK it</pre>
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default LINKit;
