import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { handleGlobalUserControl } from "@ui/Utils/helper";
import { appModule } from "../api";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {
  role: enums.roles.vendor,
};

const VendorDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models["vendor-portal"]?.users;

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  const [clientUsers, setClientUsers] = useState([]);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let action = "Created",
        res = {};

      if (!values._id) {
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecord(model, values);

        await handleGlobalUserControl(
          {
            users: [
              {
                _id: res._id,
                firstName: res.firstName,
                lastName: res.lastName,
              },
            ],
          },
          [appModule]
        );
        action = "Updated";
      }

      formMethods.reset(res);

      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " successfully");
    } catch (err) {
      console.log(err);
      let message = err?.response?.data?.message || "Something went wrong!";
      if (message?.match("duplicate")) {
        message = "User exists with phone: " + values.phone;
      }
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />
    </FormProvider>
  );
};

export default VendorDetail;
