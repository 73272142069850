import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import styles from "../../List/components/productView.module.css";
import Checkbox from "@mui/material/Checkbox";
import commonConfig from "config/commonConfig";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { isVendor, oopsNotFound } from "@ui/Utils/helper";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import "./ProductView.css";
import { MyCarousel } from "@ui/MuiComponents/index";
import { isVendorCodeEditable } from "../../api";
import CameraIcon from "@mui/icons-material/Camera";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import HoverIcons from "@ui/assets/commonIcons/customIcons/HoverIcons";
import ProductDownload from "../../ProductDownload/ProductDownload";
import { Grid } from "@material-ui/core/index";
import { CustomCopyIcon } from "../../List/List";

export const displayMeltingAsPerClient = (purity) => {
  switch (purity) {
    case 22:
    case "22":
      return 92;
    case 18:
    case "18":
      return 76;

    default:
      return purity || "";
  }
};

const ProductsView = ({
  productIndex,
  isEditable,
  selectedProducts = {},
  setSelectedProducts,
  vendorCodes = [],
  initialValues,
}) => {
  const { watch } = useFormContext();
  const quotation = watch();
  const product = quotation?.products?.[productIndex];
  const currentOrderStatus = quotation?.products?.[productIndex]?.orderStatus;
  const [openPreview, setOpenPreview] = useState({});
  const [openDownloadPopup, setOpenDownloadPopup] = React.useState({});

  return (
    <>
      <tr
        className="rows"
        style={
          {
            // border: "1px solid black",
            // paddingTop: "20px",
          }
        }
      >
        <td>
          {!isEditable && (
            <FormGroup>
              <FormControlLabel
                control={
                  <div
                    style={{
                      position: "relative",
                      // top: -30,
                      left: 32,

                      // zIndex: 100,
                    }}
                  >
                    <Checkbox
                      onChange={(event) => {
                        setSelectedProducts({
                          ...selectedProducts,
                          [productIndex]: event.target.checked,
                        });
                      }}
                      checked={!!selectedProducts?.[productIndex]}
                      color="primary"
                      sx={{
                        color: commonConfig.themeColors.green,

                        "&.Mui-checked": {
                          color: commonConfig.themeColors.green,
                        },
                      }}
                    />
                  </div>
                }
              />
            </FormGroup>
          )}
          <b>{product.id || ""}</b>
          <div>
            <img
              style={{
                height: "90%",
                width: 88,
                borderRadius: 10,
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
              src={product.images?.[0]?.signedUrl}
              alt="image not found"
              onClick={() => setOpenPreview({ product, productIndex })}
              onError={(event) => {
                event.target.src = oopsNotFound;
              }}
            />

            {productIndex === openPreview?.productIndex &&
              openPreview?.product?.images?.[0]?.signedUrl && (
                <MyCarousel
                  openPreview={!!openPreview?.product?.images?.[0]?.signedUrl}
                  setOpenPreview={() => setOpenPreview({})}
                  items={openPreview?.product?.images}
                />
              )}
          </div>

          <Grid container spacing={2} style={{ paddingTop: "16px" }}>
            <Grid item xs={6}>
              {product.id && <CustomCopyIcon text={product.id} />}
            </Grid>
            <Grid item xs={6}>
              {!isEditable && (
                <HoverIcons
                  onClick={() => setOpenDownloadPopup({ [productIndex]: true })}
                  tooltip={"Snapshot"}
                  muiIcon={<CameraOutlinedIcon />}
                  muiIconHover={<CameraIcon />}
                />
              )}
            </Grid>
          </Grid>

          {openDownloadPopup && (
            <ProductDownload
              openDownloadPopup={openDownloadPopup}
              setOpenDownloadPopup={setOpenDownloadPopup}
              product={product}
              productIndex={productIndex}
              quotation={product}
            />
          )}
        </td>

        <td>
          {isEditable && !isVendor ? (
            <RHTextField
              name={`products[${productIndex}].customerOrderId`}
              style={{ width: "100px" }}
            />
          ) : (
            product.customerOrderId
          )}
        </td>

        <td style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
          {product?.styleNo}
        </td>
        <td style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
          {product?.quantity}
        </td>
        <td style={{ textAlign: "center" }}>
          <div>{product?.grossWeight?.toFixed(3)} g</div>
          <div>{product?.netWeight?.toFixed(3)} g</div>
        </td>
        <td style={{ textAlign: "center" }}>
          <div>{product?.metalType}</div>
          <div>{displayMeltingAsPerClient(product.metalPurity)}</div>
        </td>

        <td>
          {isEditable ? (
            <RHTextField
              name={`products[${productIndex}].size`}
              style={{ width: "100px" }}
            />
          ) : (
            product.size
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          {product.exhibitionRemarks ? (
            <div
              style={{
                border: "1px solid black",
                height: "100%",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "#F5F3EF",
              }}
            >
              {/* <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <BorderColorIcon
                style={{ fontSize: "16px" }}
                // onClick={() => setIsEditable(true)}
              />
            </div> */}
              <div style={{ lineHeight: "16px", fontSize: "12px" }}>
                <p> {product?.exhibitionRemarks || ""}</p>
              </div>
            </div>
          ) : (
            <div style={{ lineHeight: "16px", fontSize: "50px" }}>
              <p> - </p>
            </div>
          )}
        </td>
        <td>
          {isEditable && !isVendor ? (
            <RHTextField name={`products[${productIndex}].adminRemarks`} />
          ) : (
            // <b>{product.adminRemarks}</b>
            <div
              style={{
                border: "1px solid black",
                height: "100%",
                padding: "10px",
                borderRadius: "5px",
                // backgroundColor: "#F5F3EF",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <BorderColorIcon
                  style={{ fontSize: "16px" }}
                  // onClick={() => setIsEditable(true)}
                />
              </div> */}
              <div style={{ lineHeight: "16px", fontSize: "12px" }}>
                <p>{product?.adminRemarks}</p>
              </div>
            </div>
          )}
        </td>
        <td>
          {isEditable &&
          !isVendor &&
          isVendorCodeEditable(product, initialValues) ? (
            <RHAutoComplete
              disableClearable
              options={vendorCodes}
              size="small"
              name={`products[${productIndex}].vendorCode`}
            />
          ) : (
            // <b>{product.adminRemarks}</b>
            <div
              style={{
                border: "1px solid black",
                height: "100%",
                padding: "10px",
                borderRadius: "5px",
                // backgroundColor: "#F5F3EF",
              }}
            >
              <div style={{ lineHeight: "16px", fontSize: "12px" }}>
                <p>{product?.vendorCode}</p>
              </div>
            </div>
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          <RenderOrderStatus
            currentOrderStatus={currentOrderStatus}
            product={product}
            productIndex={productIndex}
            isEditable={isEditable}
          />
        </td>
      </tr>
    </>
  );
};

const RenderOrderStatus = ({
  isEditable,
  currentOrderStatus,
  productIndex,
}) => {
  const { setValue, watch } = useFormContext();
  const product = watch(`products[${productIndex}]`);

  return (
    <div className={styles.dropContainer}>
      {false &&
      currentOrderStatus &&
      currentOrderStatus !== enums["vendor-portal"]?.orderStatus?.pending &&
      isEditable ? (
        <RHAutoComplete
          textFieldProps={{
            variant: "standard",
            style: { height: "24px" },
            //   sx: { input: { textAlign: "center" } },
          }}
          inputProps={{ style: { textAlign: "center" } }}
          disableClearable={true}
          style={{ width: "178px", textAlign: "center", padding: "8px" }}
          options={
            Object.values(enums["vendor-portal"]?.orderStatus).filter(
              (status) =>
                !(
                  status === enums["vendor-portal"]?.orderStatus?.assigned ||
                  status === enums["vendor-portal"]?.orderStatus?.pending
                )
            ) || []
          }
          onChange={() => {
            setValue(`products[${productIndex}].dateModified`, new Date());
          }}
          name={`products[${productIndex}].orderStatus`}
        />
      ) : (
        <Typography>
          <b>
            {isVendor &&
            product.orderStatus ===
              enums["vendor-portal"]?.orderStatus?.assigned
              ? enums["vendor-portal"]?.orderStatus?.pending
              : product.orderStatus}
          </b>
        </Typography>
      )}
    </div>
  );
};

const buttonStyle = {
  backgroundColor: commonConfig.themeColors.lightGreen,
  fontWeight: 600,
  borderRadius: 22,
  opacity: 0.8,
  height: "50px",
  marginLeft: "16px",
};

export default ProductsView;
