import React from "react";
import { formStyles } from "./formStyles";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import enums from "helpers/enums";

const RHTextField = ({
  name,
  label,
  defaultValue,
  rules,
  size,
  onKeyDown = () => {},
  autoComplete,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue ? defaultValue : ""}
      render={({ field, fieldState }) => {
        return (
          <TextField
            inputRef={field.ref}
            {...props}
            {...field}
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e);
              } else if (e.shiftKey || e.charCode === 13 || e.keyCode === 13) {
                field.onChange(field.value);
              }
            }}
            label={label}
            value={field.value ? field.value : ""}
            onChange={(event) => {
              field.onChange(event.target.value);
              props.onChange && props.onChange(event);
            }}
            inputProps={{
              style: props.style,
              ...props.inputProps,
            }}
            autoComplete={
              autoComplete || (field.name?.match("password") ? "off" : "on")
            }
            style={props.style || { width: "100%" }}
            helperText={fieldState?.error && fieldState?.error?.message}
            error={Boolean(fieldState?.error)}
            size={props.size || "small"}
            variant={props.variant || "outlined"}
            fullWidth={props.fullWidth || true}
            onFocus={(e) =>
              // To Stop Mouse scroll from Number Inputs
              props.type === enums.dataTypes.number &&
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
          />
        );
      }}
    />
  );
};

export default RHTextField;
