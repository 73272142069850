import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPaginatedData,
  paginateWithHook,
  softDeleteRecord,
} from "@ui/ComponentUtils/blueprintAPIs";
import MatTable from "@ui/components/MatTable";
import Header from "@ui/ComponentUtils/Header";
import { useRef } from "react";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";

const GeneralListPage = ({
  hardDelete,
  columns,
  model,
  mainQuery = {},
  dateFields,
  searchFields,
  userFields,
  numberFields,
  headerActions = [],
  headerTitle = "",
  title = "",
  hideAction = {},
  onRowClick = true,
  actions = [],
  parentTableRef,
  overRideRecords,
  showSelectAllCheckbox,
  navigateModel,
  tableStyle,
  paginationMeta = { pageSize: 10 },
  withHook = false,
  options,
  setParentSelectedRows,
}) => {
  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: paginationMeta.pageSize,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef();

  const getData = async (query) => {
    setListPageTableQuery(query);

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }

    query = {
      ...query,
      ...mainQuery,
      criteria: [...(mainQuery.criteria || []), ...(query.criteria || [])],
    };

    try {
      let response;
      if (withHook) {
        response = await paginateWithHook(model, query);
      } else {
        response = await getPaginatedData(model, query);
      }

      if (response && response.paginationInfo) {
        let records = response.records || [];
        if (overRideRecords) {
          records = await overRideRecords(records);
        }
        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const pageHeaderActions = [
    {
      label: "Create",
      iconName: "add",
      tooltip: "Create",
      hidden: hideAction.create,
      onClick: () => navigate("/" + (navigateModel || model) + "/create"),
    },
    ...(headerActions || []),
  ];

  const tableActions = [
    ...actions,
    {
      icon: "delete",
      tooltip: "Delete",
      disabled: !selectedRows?.length,
      isFreeAction: false,
      hidden: hideAction.delete,
      onClick: async (_) => {
        if (
          window.confirm(
            `Are you sure to ${hardDelete ? "permanently" : ""} delete ${
              selectedRows.length
            } row(s)?`
          )
        ) {
          await Promise.all(
            selectedRows?.map(async (row) => {
              await softDeleteRecord(model, row?.code, hardDelete);
            })
          );

          window.alert("Deleted Successfully");
          parentTableRef
            ? parentTableRef.current.onQueryChange()
            : tableRef.current.onQueryChange();
        }
      },
    },
  ];

  const onSelectionChange = (rows) => {
    let currentPageNum = parentTableRef
      ? parentTableRef.current.state.query.page
      : tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
    setParentSelectedRows && setParentSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };

    parentTableRef
      ? parentTableRef.current.onQueryChange(queryData)
      : tableRef.current.onQueryChange(queryData);
  };

  return (
    <div>
      {hideAction.header ? null : (
        <Header
          links={[]}
          pageTitle={headerTitle}
          actions={pageHeaderActions}
        />
      )}

      {hideAction.search ? null : (
        <AdvanceSearchFilter
          searchFields={searchFields}
          numberFields={numberFields}
          userFields={userFields}
          handleSearch={handleSearch}
          dateFields={dateFields}
        />
      )}

      <MatTable
        tableRef={parentTableRef || tableRef}
        actions={tableActions}
        columns={columns}
        data={(query) => getData(query)}
        title={title || ""}
        style={tableStyle}
        options={{
          selection: !hideAction.selection,
          search: false,
          pageSize: listPageTableQuery.pageSize,
          ...options,
        }}
        showSelectAllCheckbox={showSelectAllCheckbox}
        onRowClick={(_, selectedRow) => {
          onRowClick &&
            navigate(`/${navigateModel || model}/${selectedRow.code}`);
        }}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default GeneralListPage;
