import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { MyCarousel } from "../index";
import { oopsNotFound } from "@ui/Utils/helper";

const MainCard = ({
  title,
  image,
  fileName,
  children,
  cardStyle = {},
  sx = {},
}) => {
  const [openPreview, setOpenPreview] = React.useState(false);

  return (
    <Card sx={{ maxWidth: 345, borderRadius: "5px", ...cardStyle }}>
      {openPreview && (
        <MyCarousel
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          items={[
            {
              signedUrl: image,
              location: image,
              fileName: fileName,
              fileType: "image/*",
            },
          ]}
        />
      )}
      <img
        onClick={() => setOpenPreview(true)}
        style={{ height: "240px", width: "240px", objectFit: "contain", ...sx }} // #ffc800
        src={image}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = oopsNotFound; // this thing is not working
        }}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        {children}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
};

export default MainCard;
