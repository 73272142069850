import MatTable from "@ui/components/MatTable";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import React, { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { globalDateFormat } from "@ui/ReactHookFormControls/index";
import { getProductImageUri } from "@ui/Utils/helper";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";

const DetailContent = (props) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const [data, setData] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [linkItCart, setLinkItCart] = useState([]);

  const columns = [
    { title: "Design", render: (row) => row?.product?.styleNo },
    {
      title: "Image",
      render: (row) => (
        <>
          <img
            src={getProductImageUri(row?.product)}
            alt="img"
            style={{ height: 50, width: 50, paddingLeft: 4 }}
            onClick={() => setOpenDetailDialog(row.product)}
          />
          {openDetailDialog ? (
            <ProductDetailPopup
              open={openDetailDialog}
              onClose={() => setOpenDetailDialog(false)}
              product={row.product}
            />
          ) : null}
        </>
      ),
    },
    { title: "Quantity", field: "quantity" },
    {
      title: "Since",
      render: (row) => moment(row?.dateCreated).format(globalDateFormat.read),
    },
  ];

  useEffect(() => {
    if (values?._id) {
      (async () => {
        try {
          let res = await getSingleRecord(enums.models.carts, {
            "createdBy._id": values?._id,
          });

          setData(res?.items || []);
        } catch (err) {
          console.log(err);
        }
      })();

      (async () => {
        try {
          let res = await getSingleRecord("e-creatives/linkItCarts", {
            "createdBy._id": values?._id,
          });

          setLinkItCart(res?.items || []);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [values?._id]);

  // const location = {
  //   coords: {
  //     accuracy: 16.290000915527344,
  //     altitude: 769.4000244140625,
  //     altitudeAccuracy: 1,
  //     heading: 0,
  //     latitude: 12.969063,
  //     longitude: 77.4899714,
  //     speed: 0,
  //   },
  //   mocked: false,
  //   timestamp: 1673244660176,
  // };

  return (
    <>
      <div>
        <MatTable
          columns={columns}
          data={data || []}
          title={"ECommerce Items"}

          // onRowClick={(_, selectedRow) => {
          //   navigate(`/${model}/${selectedRow.code}`);
          // }}
          // onSelectionChange={onSelectionChange}
        />
      </div>

      <div style={{ marginTop: "24px" }}>
        <MatTable
          columns={columns}
          data={linkItCart || []}
          title={"LINK it Items"}

          // onRowClick={(_, selectedRow) => {
          //   navigate(`/${model}/${selectedRow.code}`);
          // }}
          // onSelectionChange={onSelectionChange}
        />
      </div>
    </>
  );
};

export default DetailContent;
