import React, { useState } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";
import LINKit from "./LINKit";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Auth",
    render: (row) =>
      (row?.includedUsers || []).map((user) => user?.firstName).join(", "),
  },

  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const CataloguesList = () => {
  return (
    <div>
      <LINKit columns={columns} />
    </div>
  );
};

export default CataloguesList;
