import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

const baseApiUrl = commonConfig.baseApiUrl;

export const syncCatToProducts = async (catalogue) => {
  try {
    const url =
      baseApiUrl + "c/" + enums.models.products + "/syncCatToProducts";

    const res = await HTTP.post(url, { catalogueId: catalogue._id });
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createExpiryLink = async (body) => {
  try {
    const url =
      commonConfig.baseApi.linkIt + enums.models["e-creatives"].expiryLinks;

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addToCart = (
  products,
  { cartItems, setCartItems },
  { decrease = false } = {}
) => {
  const tempItems = [...cartItems]; // [{product: {}, quantity: 1}]

  products.map((prod) => {
    let existingFlag = false;
    tempItems.find((item, itemIndex) => {
      if (item?.product?.sku === prod.sku) {
        if (decrease) {
          if (!(tempItems[itemIndex].quantity - 1 > 0)) {
            tempItems.splice(itemIndex, 1);
          } else {
            tempItems[itemIndex].quantity -= 1;
          }
        } else {
          tempItems[itemIndex].quantity += 1;
        }

        existingFlag = true;

        return true;
      }
    });
    if (!existingFlag) {
      tempItems.push({ product: prod, quantity: 1 });
    }
  });

  setCartItems(tempItems);
};
