import React, { useEffect, useRef, useState } from "react";
import enums from "helpers/enums";
import {
  formatDateToRead,
  imageExpired,
  isAdmin,
  isVendor,
  oopsNotFound,
  uniqueArray,
  userDetails,
  userIdentifier,
} from "@ui/Utils/helper";
import { downloadPdf, handleReceived, paginateWithHook } from "../api";
import { useNavigate } from "react-router-dom/dist/index";
import MatTable from "@ui/components/MatTable";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";
import Header from "@ui/ComponentUtils/Header";
import { Button, Grid, Tooltip } from "@material-ui/core/index";
import { Checkbox } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import { MyCarousel } from "@ui/MuiComponents/index";
import { defaultFilter } from "../Detail/Detail";
import FilterComponent, { blueColor, goldColor } from "./FilterComponent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import "./customOrders.css";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import LazyLoad from "@ui/Actions/LazyLoad/index";

const tabSessionName = "op.customOrders.currentTab";
export const customOrderColor = "#fff4d1";
export const onlineOrderColor = "#e3ebff";

const CustomOrdersList = () => {
  const model = enums.models["e-creatives"]?.customOrders;
  const searchFields = [
    "orderId",
    "phone",
    "email",
    "items.product.sku",
    "items.product.styleNo",
    "items.orderStatus",
    "customer.companyDetails.name",
    "items.vendorCode",
    "partyOrderNumber",
    "comment",
    "manager.zones",
    "orderType",
    "orderPriority",
  ];
  const userFields = [
    "$createdBy.firstName",
    "$createdBy.lastName",
    "$customer.manager.firstName",
    "$customer.manager.lastName",
  ];
  const dateFields = ["dateCreated", "items.dateDelivered", "items.dueDate"];

  const headerTitle = "Custom Orders";
  const hideAction = { create: true, delete: true, selection: true };
  const navigate = useNavigate();
  const tableRef = useRef();
  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 1000,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalCalc, setTotalCalc] = useState({ grossWeight: 0, netWeight: 0 });
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [currentTab, setCurrentTab] = useState(1); // State to manage active tab
  const [openPreview, setOpenPreview] = useState();
  const [selectedFilter, setSelectedFilter] = useState();
  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpandRow = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    const curTab = window.sessionStorage.getItem(tabSessionName);

    if (curTab !== null && !isNaN(curTab)) {
      setCurrentTab(Number(curTab));
    } else {
      setCurrentTab(1);
    }
  }, []);

  const renderDescription = (descriptionArray, index) => {
    const MAX_CHARS = 250;

    return descriptionArray.map((description) => {
      if (!description) {
        return <div></div>;
      }

      if (expandedRows[index]) {
        return (
          <div>
            {description}{" "}
            <Button onClick={() => toggleExpandRow(index)}>Read Less</Button>
          </div>
        );
      }

      if (description.length <= MAX_CHARS) {
        return <div>{description}</div>;
      }

      const shortDescription = description.substring(0, MAX_CHARS) + "...";
      return (
        <div>
          {shortDescription}{" "}
          <Button onClick={() => toggleExpandRow(index)}>Read More</Button>
        </div>
      );
    });
  };

  const columns = [
    {
      title: "Recvd.",
      sorting: false,
      render: (row) => (
        <div>
          {!isVendor && (
            <div>
              <Tooltip
                title={`Mark as ${row.received ? "Not Received" : "Received"}`}
              >
                <Checkbox
                  checked={!!row.received}
                  color="primary"
                  onChange={async () => {
                    if (
                      window.confirm(
                        `Are you sure to mark ${row.orderId} as ${
                          row.received ? "NOT Received" : "Received"
                        }? `
                      )
                    ) {
                      await handleReceived(row, !row.received);

                      tableRef.current.onQueryChange(listPageTableQuery);
                    }
                  }}
                />
              </Tooltip>
            </div>
          )}

          <div style={{ paddingLeft: "8px" }}>
            <MyHoverIcons
              muiIcon={<RemoveRedEyeOutlinedIcon />}
              muiIconHover={<RemoveRedEyeIcon />}
              onClick={() => navigate(`/${model}/${row.code}`)}
              tooltip={"View"}
            />
          </div>

          <div style={{ paddingLeft: "8px" }}>
            <MyHoverIcons
              muiIcon={<PictureAsPdfOutlinedIcon />}
              muiIconHover={<PictureAsPdfIcon />}
              onClick={() => downloadPdf(row)}
              tooltip={"PDF"}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Media",
      field: "items.attachments",
      sorting: false,
      render: (row) => {
        let attachments = (row.items || [])
          .filter((item) => defaultFilter(item))
          .map((item) => item.attachments)
          .flat();

        const attachmentsCount = attachments?.length;

        attachments = attachmentsCount
          ? attachments
          : [
              {
                location: oopsNotFound,
                fileName: "Not Found",
                fileType: "image/png",
              },
            ];

        return (
          <div>
            <LazyLoad>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  cursor: "pointer",
                  marginTop: "24px",
                }}
                onClick={() => setOpenPreview(row.code)}
              >
                <img
                  src={
                    (row.items || []).filter((item) => defaultFilter(item))[0]
                      ?.attachments?.[0]?.signedUrl || oopsNotFound
                  }
                  onError={(event) => {
                    event.target.src = imageExpired;
                  }}
                  style={{ height: 50, width: 50, objectFit: "contain" }}
                />

                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    background: "#2d6e9c",
                    color: "white",
                    padding: "3px 6px",
                    borderRadius: "50%",
                    fontSize: 12,
                    fontWeight: "bold",
                    transform: "translate(50%, -50%)",
                  }}
                >
                  {attachmentsCount}
                </div>
              </div>
            </LazyLoad>
            {openPreview && (
              <MyCarousel
                openPreview={openPreview === row.code}
                setOpenPreview={() => setOpenPreview()}
                items={attachments}
              />
            )}
          </div>
        );
      },
    },
    {
      title: "Order ID",
      render: (row) => {
        return (
          <div
            style={{ minWidth: "100px", color: "#2d6e9c", fontWeight: "bold" }}
          >
            {row?.orderId}
          </div>
        );
      },
    },
    {
      title: "Description",
      field: "items.description",
      hidden: !isVendor,
      render: (row) => {
        return (
          <div style={{ minWidth: "400px" }}>
            {renderDescription(
              (row.items || [])

                .map(
                  (item, i) =>
                    defaultFilter(item) && `${i + 1}. ${item.description}`
                )
                .filter((item) => item),
              row.tableData.id
            )}
          </div>
        );
      },
    },
    {
      title: "Qty\nWeight",
      field: "items.grossWeight",
      hidden: !isVendor,
      render: (row) => {
        return (
          <div>
            <div>
              {row.items
                .filter((item) => defaultFilter(item))
                .reduce(
                  (previousValue, currentValue) =>
                    previousValue + (currentValue.quantity || 0),
                  0
                )}
            </div>
            <div>
              {row.items
                .filter((item) => defaultFilter(item))
                .reduce(
                  (previousValue, currentValue) =>
                    previousValue + (currentValue.grossWeight || 0),
                  0
                )}
            </div>
          </div>
        );
      },
    },
    {
      title: "#Party",
      field: "partyOrderNumber",
      hidden: isVendor,
      render: (row) => (
        <div>
          <div style={{ color: "#fc5047", fontWeight: "bold" }}>
            {row.partyOrderNumber}
          </div>
          {row.comment ? (
            <div style={{ color: "grey" }}> {`(${row.comment})`}</div>
          ) : null}
        </div>
      ),
    },
    {
      title: "Company",
      field: "customer.companyDetails.name",
      render: (row) => (
        <div style={{ fontWeight: "bold", color: "grey" }}>
          {row?.customer?.companyDetails?.name ||
            (row.customer?.firstName || "") +
              " " +
              (row.customer?.lastName || "")}
        </div>
      ),
      hidden: isVendor,
    },

    {
      title: "Vendors",
      field: "items.vendorCode",
      render: (row) => (
        <div style={{ fontWeight: "bold" }}>
          {(row.items || [])
            .map((item) => item.vendorCode)
            .filter((v) => v)
            .join(", ")}
        </div>
      ),
      hidden: isVendor,
    },

    {
      title: "Zone",
      field: "customer.manager.firstName",
      render: (row) => (
        <div>
          <div>
            {(row.customer?.manager?.firstName || "") +
              " " +
              (row.customer?.manager?.lastName || "")}
          </div>
          <div style={{ fontSize: "12px" }}>
            {row.customer?.manager?.zones?.length
              ? `(${row.customer?.manager?.zones.join(", ")})`
              : ""}
          </div>
        </div>
      ),
      hidden: isVendor,
    },
    {
      title: "Priority",
      field: "orderPriority",
      render: (row) => (
        <div
          style={{
            color:
              row.orderPriority === enums.customOrderPriority.normal
                ? "green"
                : "orange",
          }}
        >
          {row.orderPriority}
        </div>
      ),
    },
    {
      title: "Created",
      render: (row) => (
        <div style={{ minWidth: "110px" }}>
          <div>
            {formatDateToRead(row?.dateCreated, globalDateTimeFormat.read)}
          </div>
          <div style={{ fontSize: "12px" }}>
            {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
          </div>

          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {row?.createdBy?.role}
          </div>
        </div>
      ),
    },
    {
      title: `Due Date`,
      field: "items.dueDate",
      render: (row) => {
        let dues = [];
        (row.items || []).map((item) => {
          if (defaultFilter(item) && item?.dueDate) {
            dues.push(formatDateToRead(item.dueDate));
          }
        });

        dues = uniqueArray(dues);

        return (
          <div style={{ minWidth: "110px", color: "#ff8800" }}>
            {dues.join(", ")}
          </div>
        );
      },
    },
    {
      title: `Status`,
      field: "items.orderStatus",
      render: (row) => {
        let statuses = [],
          delivereds = [];

        (row.items || []).map((item) => {
          if (defaultFilter(item)) {
            statuses.push(item?.orderStatus);

            if (item?.dateDelivered) {
              delivereds.push(
                formatDateToRead(item?.dateDelivered, globalDateTimeFormat.read)
              );
            }
          }
        });
        statuses = uniqueArray(statuses);
        delivereds = uniqueArray(delivereds);

        return (
          <div style={{ minWidth: "110px", color: "#2d6e9c" }}>
            <div>{statuses.join(", ")}</div>
            <div>{delivereds.length ? `${delivereds.join(", ")}` : ""}</div>
          </div>
        );
      },
    },
  ];

  const handleTabChange = (value) => {
    setCurrentTab(value);
    window.sessionStorage.setItem(tabSessionName, value);
    tableRef.current.onQueryChange({ ...listPageTableQuery, page: 0 });
  };

  const getData = async (query = {}) => {
    setListPageTableQuery(query);
    let tabCondition = {};

    if (isVendor) {
      tabCondition["items.vendorCode"] = { $in: userDetails.vendorCode };
      tabCondition["items.orderStatus"] = {
        $in: [
          enums["vendor-portal"].orderStatus.accepted,
          enums["vendor-portal"].orderStatus.assigned,
          enums["vendor-portal"].orderStatus.canceled,
          enums["vendor-portal"].orderStatus.delivered,
          enums["vendor-portal"].orderStatus.inProgress,
          enums["vendor-portal"].orderStatus.resent,
        ],
      };
    } else if (!isAdmin) {
      if (userIdentifier.isZoneManager) {
        tabCondition["$or"] = tabCondition["$or"] || [];
        tabCondition["$or"].push({ "customer.manager._id": userDetails._id });
        tabCondition["$or"].push({ "createdBy._id": userDetails._id });
      }
      // Sales Head can see all orders, Sales Person can see only his Orders
      else if (userIdentifier.isSalesPerson) {
        //   // currently, only Sales Person has create access other than Admin.. instead of Order Creator, we used Sales Person role ;)
        tabCondition["createdBy._id"] = userDetails._id;
      }
    }

    if (selectedFilter) {
      tabCondition.orderType = selectedFilter.orderType;
    }

    if (currentTab === 0) {
      tabCondition.received = true;
    } else {
      tabCondition.received = { $ne: true };
    }

    if (
      query.criteria?.[query.criteria.length - 1]?.searchElements?.length ||
      filterCriteria?.[filterCriteria.length - 1]?.searchElements?.length
    ) {
      if (query.criteria?.[0]?.$or) {
        query.criteria.unshift(tabCondition);
      } else {
        query.criteria = query.criteria || [];
        query.criteria[0] = tabCondition;
      }
    } else {
      query.criteria = [tabCondition, {}];
    }

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
    };

    try {
      let response = await paginateWithHook(model, query);

      if (response && response.paginationInfo) {
        const records = response.records || [];

        setTotalCalc({
          grossWeight: response.paginationInfo.totalGrossWeight || 0,
          netWeight: response.paginationInfo.totalNetWeight || 0,
        });
        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const onSelectionChange = (rows) => {
    setSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };
    setFilterCriteria(filterQuery);
    tableRef.current.onQueryChange(queryData);
  };

  const headerActions = [
    {
      label: "Create",
      iconName: "add",
      tooltip: "Create",
      onClick: () => navigate("/" + model + "/create"),
      hidden: userIdentifier.isVendor,
    },
  ];

  return (
    <div>
      <StickySearchFilter
        headerTitle={headerTitle}
        headerActions={headerActions}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        dateFields={dateFields}
        handleSearch={handleSearch}
        listPageTableQuery={listPageTableQuery}
        searchFields={searchFields}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        tableRef={tableRef}
        userFields={userFields}
      />

      <div style={{ marginTop: "24px" }}>
        <MatTable
          tableRef={tableRef}
          columns={columns}
          data={(query) => getData(query)}
          title={`Gross -- >>> ${totalCalc.grossWeight?.toFixed(3)}`}
          options={{
            selection: !hideAction.selection,
            search: false,
            pageSize: listPageTableQuery.pageSize,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000, 2000, 5000],
            emptyRowsWhenPaging: false,
            rowStyle: (row) => ({
              backgroundColor:
                row.orderType === enums.customOrderType.custom
                  ? customOrderColor
                  : onlineOrderColor,
            }),
          }}
          onSelectionChange={onSelectionChange}

          // onRowClick={(_, selectedRow) => {
          // }}
        />
      </div>
      <ScrollTopButton />
    </div>
  );
};

const StickySearchFilter = ({
  currentTab,
  handleTabChange,
  selectedFilter,
  setSelectedFilter,
  tableRef,
  listPageTableQuery,
  searchFields,
  userFields,
  handleSearch,
  dateFields,
  headerActions,
  headerTitle,
}) => {
  return (
    <div className="sticky-header">
      <Grid
        container
        spacing={2}
        style={{
          zIndex: 100,
          backgroundColor: "white",
          padding: "24px",
          paddingBottom: 0,
        }}
      >
        <Header links={[]} pageTitle={headerTitle} actions={headerActions} />

        <Grid item xs={12}>
          <AdvanceSearchFilter
            searchFields={searchFields}
            userFields={userFields}
            handleSearch={handleSearch}
            dateFields={dateFields}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justify="space-between"
          style={{ marginTop: "24px" }}
        >
          <Grid item xs={2}>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: currentTab === 0 ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => handleTabChange(0)}
            >
              Completed
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: currentTab === 1 ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => handleTabChange(1)}
            >
              In Progress
            </Button>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={3}>
            <FilterComponent
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              tableRef={tableRef}
              listPageTableQuery={listPageTableQuery}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default CustomOrdersList;
